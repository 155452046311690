import  { useContext, useCallback } from 'react';
import  { AppBaseContext } from 'components/provider/pc/provider-pc';


const useLogin = () => {
  const { state, dispatch } = useContext(AppBaseContext);

  const showLogin = useCallback(() => {
    dispatch({ type: 'showLogin' });
  }, []);

  const hideLogin =  useCallback(() => {
    dispatch({ type: 'hideLogin' });
  }, []);
  return { isShowLogin: state.isShowLogin, showLogin, hideLogin };
};

export default useLogin;
