// 后端接口都是下划线链接，这里忽略驼峰校验
/* eslint-disable camelcase */
import { getJson, deleteJson, postJSON, postFormData } from 'components/api/request';
import { ApiRequest, ResponseBase } from 'components/api/types';

export enum CHANNEL {
  PHONE = 'phone'
}
interface GetApiV2AccountContactReq {
  /** 是否返回触发前端操作的状态码. */
  trigger?: boolean;
}
interface GetApiV2AccountContactRespData {
  /** 手机号的脱敏展示 */
  phone: string;
  /** 手机号的国家代码 */
  phone_country_code: string;
  /** 是否已经验证过 */
  is_answered: number;
  /** 登录类型 */
  login_type: string;
  /** 签名 */
  signature: string;
  /** openid */
  openid: string;
}

interface PostApiV2AccountContactVerifySendReq {
  /** 渠道。示例：phone  */
  channel: CHANNEL;
  /** 渠道_UID。示例：15285876321 。  */
  channelUid: string;
  /** 国家代码。phone渠道时默认为86。示例：86  */
  countryCode?: string;
}

interface PostApiV2AccountContactVerifyCodeReq {
  /** 渠道。示例：phone  */
  channel: CHANNEL;
  /** 渠道_UID。示例：15285876321 。  */
  channelUid: string;
  /** 国家代码。phone渠道时默认为86。示例：86  */
  countryCode?: string;
  /** 验证码  */
  code?: string;
}
/**
 * 账号-联系方式 - 获取
 */
export const getApiV2AccountContact: ApiRequest<
  GetApiV2AccountContactReq | undefined,
  ResponseBase<GetApiV2AccountContactRespData>
> = param => getJson({
  url: '/api/v2/account/contact',
  data: { ...param },
});


/**
 * 账号-联系方式-渠道配置 - 发送验证码
 */
export const postApiV2AccountContactVerifySend: ApiRequest<
  PostApiV2AccountContactVerifySendReq,
  ResponseBase<GetApiV2AccountContactRespData>
> = ({ channel, channelUid, countryCode }) => postJSON({
  url: '/api/v2/account/contact/verify/send',
  data: {
    channel,
    channel_uid: channelUid,
    country_code: countryCode,
  },
});
/**
 * 账号-联系方式-渠道配置 - 通过验证码来更新
 */
export const postApiV2AccountContactVerifyCode: ApiRequest<
  PostApiV2AccountContactVerifyCodeReq,
  ResponseBase<GetApiV2AccountContactRespData>
> = ({ channel, channelUid, countryCode, code }) => postJSON({
  url: '/api/v2/account/contact/verify/code',
  data: {
    code,
    channel,
    channel_uid: channelUid,
    country_code: countryCode,
  },
});

