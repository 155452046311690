import React from 'react';
import classNames from 'classnames';
import { Avatar } from 'components/user';
import Icon from 'components/icon';
import { TcIconEsc, TcIconManage, TcIconQQ } from 'components/IconSystem';
import {
  href_profile as hrefProfile,
  href_dashboard as hrefDashboard,
} from 'components/href-helper';
import { Tooltip } from 'tdesign-react';
import { getEnterpriseEntry } from 'components/util/enterprise-entry';
import { ENTERPRISE_VERSION } from 'components/constants/enterprise';
import PropTypes from 'prop-types';

class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_slide: false,
    };

    this.onClickWindow = this.onClickWindow.bind(this);
  }

  componentDidMount() {
    window.addEventListener('click', this.onClickWindow, false);
  }

  onClickWindow() {
    this.setState({ is_slide: false });
  }

  onClickAvatar(e) {
    e.stopPropagation();
    this.setState({ is_slide: !this.state.is_slide });
  }

  render() {
    const { props, state } = this;
    const enterpriseEntry = getEnterpriseEntry();
    const hasEnterprise = enterpriseEntry
            && props.productInfo.is_tencent_product
            && props.productInfo.access_type === ENTERPRISE_VERSION.PRIVATE;

    return (
      <div className="notification">
        {props.is_admin && (
          <Tooltip
            content="管理后台"
            destroyOnClose
            placement="top"
            showArrow
            theme="light"
          >
            <a title="管理后台" href={hrefDashboard(props.is_admin)} target="_blank" className="link_enter" rel="noreferrer">
              <TcIconManage />
            </a>
          </Tooltip>
        )}
        <span className="login_avatar" role="button" tabIndex="0"
          onClick={e => this.onClickAvatar(e)} onKeyPress={e => this.onClickAvatar(e)}>
          <Avatar src={props.avatar} />
        </span>
        <div className={classNames('menu_list', { slide_show: state.is_slide, has_enterprise: hasEnterprise })}>
          <a href={hrefProfile(props.user_id)} className="menu_item">
            <Icon type="mine-light" className="tcIcon_mine" />
            个人中心
          </a>
          {hasEnterprise && (
            <a href={`https://${location.host}/enterprise/${props.cid}/home`} target="_blank" className="menu_item" rel="noreferrer">
              <TcIconQQ />
              腾讯惜议
            </a>
          )}
          <button onClick={props.onLogout} className="menu_item">
            <TcIconEsc />
            退出登录
          </button>
        </div>
      </div>
    );
  }
}

Notification.propTypes = {
  productInfo: PropTypes.object,
  cid: PropTypes.number,
  is_admin: PropTypes.bool,
  avatar: PropTypes.string,
  user_id: PropTypes.number,
  onLogout: PropTypes.func,
};

export { Notification };
