import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { LoginPanel } from 'components/user/login-panel';

import './user-login-modal.less';

export class UserLoginModal extends Component {

    render() {

        const { props, state } = this;

        return (
            <div className="user-login-modal">
                <LoginPanel onSuccess={props.onSuccess} onError={props.onError} />
            </div>
        );

    }

}

UserLoginModal.propTypes = {
    onError: PropTypes.func,
    onSuccess: PropTypes.func
};

UserLoginModal.defaultProps = {
    onError() { console.log('onError 未绑定') },
    onSuccess() { console.log('onSuccess 未绑定') }
};
