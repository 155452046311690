/* eslint-disable camelcase */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import '@tencent/coral-style';

import {
  get_api_account,
  get_api_user_info,
  postApiV2Logout,
  get_api_faqs,
  post_api_teamblog_list,
  get_api_products_info,
  initUserInfo,
  get_api_products_menu as getApiProductsMenu,
  getApiV2AccountContact,
} from 'components/api';
import {
  href_home,
  href_faqs_more,
  href_blog_archive,
  hrefProductBlacklist,
  href_link_jump,
  routeToRoadmap,
  routeToChangeLog,
} from 'components/href-helper';
import { Avatar } from 'components/user';
import { Notification } from './notification';
import { message } from '@tencent/ten-design-react';
import defaultLogo from 'components/assets-image/rank-deck/default_prod.jpg';
import { getEnterpriseEntry } from 'components/util/enterprise-entry';
import { getProductId } from 'components/util';
import { ENTERPRISE_VERSION } from 'components/constants/enterprise';


import './style.less';
import 'components/css/pc/td-reset.less';


const ROUTER = {
  HOME: {
    key: 'home',
    path: [''],
  },
  FAQ: {
    key: 'faq',
    path: [
      'faqs-more', // 常见问题首页
      'faqs', // 常见问题详情页 （旧）
    ],
  },
  BLOG: {
    key: 'blog',
    path: [
      'blog-archive', // 博客首页
      'blog', // 博客详情页
    ],
  },
  ROADMAP: {
    key: 'roadmap',
    path: ['roadmap', 'topic-detail'],
  },
  CHANGE_LOG: {
    key: 'change-log',
    path: ['change-log'],
  },
};
const cls = 'product-header';
class ProductHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountInfo: {},
      userInfo: {},
      productInfo: {},
      menu: {},
    };
    this.activeTab = this.getActiveTab();
    this.productId = getProductId();
  }

  async componentDidMount() {
    const { props } = this;
    const { productId } = this;

    await initUserInfo(this.productId, location.search).catch(() => {});
    this.initMenu();
    get_api_account().then((resp) => {
      this.setState({ accountInfo: resp.data });
      props.getAccountInfo(resp);
    });

    get_api_user_info({ productId })
      .then((res) => {
        // 检测管理员是否绑定手机号
        if (res.data.is_admin) {
          getApiV2AccountContact({ trigger: 1 });
        }
        this.setState({ userInfo: res.data });
        this.goBlackList(res.data.credit_level);
        props.getUserInfo(res);
      })
      .catch((err) => {
        // 该情况为企业内部账户访问了普通社区，后台已经清除了登录态
        if (err.response?.status === 5002) {
          message.error('您不是管理员，请使用 QQ 或微信账号登录', 3000, () => {
            window.location.reload();
          });
        }
        // 修复data空数组的报错
        props.getUserInfo({ ...err, data: {} });
      });

    get_api_products_info(productId).then((res) => {
      if (res.data && res.data.product_name) {
        document.title = res.data.product_name;
      }
      this.setState({ productInfo: res.data });
      this.goBlackList(res.data.credit_level);
      props.getProductInfo(res);
    });

    if (props.getFaqs) {
      get_api_faqs({ productId }).then((res) => {
        if (typeof props.getFaqs === 'function') {
          props.getFaqs(res);
        }
      });
    }

    if (props.getTeamblog) {
      post_api_teamblog_list({ productId }).then((res) => {
        if (typeof props.getTeamblog === 'function') {
          props.getTeamblog(res);
        }
      });
    }
  }

  // 初始化菜单
  initMenu = () => {
    getApiProductsMenu(this.productId)
      .then((res) => {
        if (res?.data) {
          this.setState({ menu: res.data });
        }
      });
  }

  getActiveTab = () => {
    const reg = /^\/products?\/\d+\/?/;
    const path = location.pathname.replace(reg, '');
    // 首页路径完全一致
    if (ROUTER.HOME.path.some(key => key === path)) {
      return ROUTER.HOME.key;
    }
    if (ROUTER.FAQ.path.some(key => path.indexOf(key) !== -1)) {
      return ROUTER.FAQ.key;
    }
    if (ROUTER.BLOG.path.some(key => path.indexOf(key) !== -1)) {
      return ROUTER.BLOG.key;
    }
    if (ROUTER.ROADMAP.path.some(key => path.indexOf(key) !== -1)) {
      return ROUTER.ROADMAP.key;
    }
    if (ROUTER.CHANGE_LOG.path.some(key => path.indexOf(key) !== -1)) {
      return ROUTER.CHANGE_LOG.key;
    }
    return '';
  }

  // 跳转产品被拉黑页面
  goBlackList(credit_level) {
    const reg = /blacklist/;
    const isBlacklist = reg.test(location.pathname);
    if (credit_level === 'high_risk' && !isBlacklist) {
      location.href = hrefProductBlacklist();
    }
  }

  // 退出登陆
  onLogout() {
    localStorage.removeItem('isGood'); // 去掉第一次标记好问题标识。
    postApiV2Logout().then(res => location.reload());
  }

  render() {
    const { props, state } = this;
    const { menu } = this.state;
    const isLogin = state.userInfo.user_id;
    const { product_name, logo, related = {} } = state.productInfo;

    const hasFaqs = menu.faq || props.hasFaqList;
    const hasBlog = menu.blog || props.hasBlogList;

    const showRelated = props.hasRelated && related.related_name && related.related_link;
    const showEnterprise = state.productInfo.is_tencent_product
            && state.productInfo.access_type === ENTERPRISE_VERSION.PRIVATE;

    const backToOrigin = showRelated || showEnterprise;

    const enterpriseEntry = getEnterpriseEntry() && showEnterprise;

    const goBackRender = () => {
      if (related.related_link) {
        const relatedName = showRelated && related.related_name;

        return (
          <a href={href_link_jump(related.related_link)} target="_blank" rel="noreferrer">
            &lt;&nbsp;返回&nbsp;{relatedName}
          </a>
        );
      }

      return null;
    };

    const splitRender = () => {
      if (related.related_link && enterpriseEntry) {
        return <span className="back-to-origin-split">|</span>;
      }

      return null;
    };

    const enterpriseEntryRender = () => {
      if (enterpriseEntry) {
        return (
          <a
            href={`https://${location.host}/enterprise/${state.accountInfo.cid}/home`}
            target="_blank" rel="noreferrer"
          >
            腾讯惜议
          </a>
        );
      }

      return null;
    };

    const enterpriseSubject = () => `https://${location.host}/enterprise/${state.accountInfo.cid}/subject`;

    return (
      <Fragment>
        <div className={classNames('header', props.className)}>
          {backToOrigin && (
            <div className="back-to-origin">
              <div className="header__back-info">
                {goBackRender()}
                {splitRender()}
                {enterpriseEntryRender()}
              </div>
            </div>
          )}

          <div className="header-main container">
            <div className="header-left">
              <a className="header_logo" href={this.productId === '404148' ? enterpriseSubject() : href_home()}>
                {logo && (
                  <span className="logo">
                    <Avatar defaultImg={defaultLogo} src={logo} />
                  </span>
                )}
                <span className="text">{product_name}</span>
              </a>
              <div className="header_nav">
                <div className="nav_menu">
                  <a
                    className={classNames(`${cls}__nav-item`, {
                      [`${cls}__nav-item--active`]: this.activeTab === ROUTER.HOME.key,
                    })}
                    href={href_home()}
                  >
                    首页
                  </a>
                  {menu.roadmap && (
                    <a
                      className={classNames(`${cls}__nav-item`, {
                        [`${cls}__nav-item--active`]: this.activeTab === ROUTER.ROADMAP.key,
                      })}
                      href={routeToRoadmap()}
                    >
                      功能投票
                    </a>
                  )}
                  {hasFaqs && (
                    <a
                      className={classNames(`${cls}__nav-item`, {
                        [`${cls}__nav-item--active`]: this.activeTab === ROUTER.FAQ.key,
                      })}
                      href={href_faqs_more()}
                    >
                      常见问题
                    </a>
                  )}
                  {hasBlog && (
                    <a
                      className={classNames(`${cls}__nav-item`, {
                        [`${cls}__nav-item--active`]: this.activeTab === ROUTER.BLOG.key,
                      })}
                      href={href_blog_archive()}
                    >
                      团队博客
                    </a>
                  )}
                  {menu['feat-log'] && (
                    <a
                      className={classNames(`${cls}__nav-item`, {
                        [`${cls}__nav-item--active`]: this.activeTab === ROUTER.CHANGE_LOG.key,
                      })}
                      href={routeToChangeLog()}
                    >
                      更新日志
                    </a>
                  )}
                </div>
              </div>
            </div>
            {isLogin ? (
              <Notification
                {...state.userInfo}
                productInfo={state.productInfo}
                cid={state.accountInfo.cid}
                onLogout={() => this.onLogout()}
              />
            ) : (
              <div className="header_enter">
                <span className="user_login" onClick={props.onShowLogin} role="button" tabIndex="0" onKeyPress={props.onShowLogin}>
                  登录
                </span>
              </div>
            )}


          </div>
        </div>
      </Fragment>
    );
  }
}

ProductHeader.defaultProps = {
  getAccountInfo: () => {},
  getUserInfo: () => {},
  getProductInfo: () => {},
  onShowLogin: () => {},
  hasRelated: true,
  hasFaqList: false,
  hasBlogList: false,
};

ProductHeader.propTypes = {
  userInfo: PropTypes.object,
  className: PropTypes.string,
  getAccountInfo: PropTypes.func,
  getUserInfo: PropTypes.func,
  getProductInfo: PropTypes.func,
  onShowLogin: PropTypes.func,
  related_name: PropTypes.string,
  related_link: PropTypes.string,
  hasFaqList: PropTypes.bool,
  hasBlogList: PropTypes.bool,
  getFaqs: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  getTeamblog: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  hasRelated: PropTypes.bool,
};

export { ProductHeader };
