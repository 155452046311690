import React, { Fragment } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import { Mask } from 'components/mask';
import { TcIconShut } from 'components/IconSystem';

import './style.less';

export const Modal = function (props) {
    // 当前页面被旧框架 iFrame 加载使用
    const superWindow = window.top;

    return createPortal(
        <Fragment>

            {props.onClose && <Mask onClick={event => props.onClose(event)} />}

            <div className="modal">

                <div className="modal-content">
                    {props.onClose && (
                        <div
                            className="modal-close"
                            onClick={event => props.onClose(event)}
                            role="button"
                            tabIndex="0"
                        >
                            <TcIconShut />
                        </div>
                    )}

                    {props.children}
                </div>
            </div>
        </Fragment>
        , superWindow.document.body);
};

Modal.propTypes = {
    onClose: PropTypes.func,
    children: PropTypes.element
};
