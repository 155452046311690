import React, { createContext, useReducer } from 'react';
import { reducer, initialState } from './reducer';
export const AppBaseContext = createContext<{
  state: any,
  dispatch: React.Dispatch<any>
}>({
  state: {},
  dispatch: () => { },
});

const Provider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <AppBaseContext.Provider value={{ state, dispatch }}>
      {props.children}
    </AppBaseContext.Provider>
  );
};

export default Provider;
